@import '../../../../../../lib/uio2/css/base/base.less';
@import '../../../../../../lib/uio2/css/typography.less';
@import '../../../../../../lib/uio2/css/grid.less';
@import '../../../../../../lib/uio2/css/components.less';
@import '../../../../../../lib/uio2/css/documents.less';
@import '../../../../../../lib/uio2/css/listings.less';
@import '../../../../../../lib/uio2/css/search.less';

@responsiveConsentBreakpoint: ~"only screen and (max-width: 768px)";
@tabletConsentBreakpoint: ~"only screen and (max-width: 1070px)";
@import '../../../../../groups/all/src/resources/css/consent.less';

@import '../../../../../../lib/uio2/css/frontpage-edit.less';
@import '../../../../../../lib/uio2/css/embedded.less';
@import '../../../../../../lib/uio2/css/print.less';

@import 'base/variables.less';
@import '../../../../../groups/uio-museum/src/resources/css/common.less';
@import "../../../../../../lib/uio2/css/base/mixins.less";

@historicalMuseumBg: #0A8800;

@boxRowBlackBg: #111;         // Custom "row-color-black" on a box
@boxRowCustomGreyBg: #E3E3E3; // Custom color used on social footer row

// Seasonal changes by historical museum request (changes colors each year)
// OBS: Remember to change arrows/logo to white/black accordingly
@historicalMuseumBg: rgba(19, 255, 80, 1); // #733679, yellow: #FFFF00
@historicalMuseumColor: #000; // #fff
@boxRowGreenBg: @historicalMuseumBg;     // Custom "row-color-yellow" on a box
@exhibitionInfoBg: @historicalMuseumBg;      // Custom color for "exhibition-info" for "info-box" on an exhibition article
@exhibitionInfoColor: @historicalMuseumColor;
@exhibitionCategoryBg: @historicalMuseumBg;  // Custom color for category/tag in exhibition feeds
@exhibitionCategoryColor: @historicalMuseumColor;
@globalMenuBg: @historicalMuseumBg;
@globalMenuColor: @historicalMuseumColor;

// Header

#header {
  align-items: normal;
}

#head-wrapper {
  min-height: 200px;
}

#logo {
  background: url("../images/logo.svg") no-repeat 0 0;
  background-size: auto 100%;
  display: block;
  height: 135px;
  width: 100px;
}

body.en #logo {
  background-image: url("../images/logo-en.svg");
}

.the-frontpage:not(.sidebar-menu-wrapper-visible):not(.vrtx-editor-frontpage) #logo {
  background-image: url("../images/logo-big.svg");
  height: 279px;
  width: 300px;
  z-index: 0;
}
body.en.the-frontpage:not(.sidebar-menu-wrapper-visible):not(.vrtx-editor-frontpage) #logo {
  background-image: url("../images/logo-big.svg");
}
.the-frontpage:not(.vrtx-editor-frontpage) #global-menu {
  margin-top: 49px;
}

.the-frontpage:not(.sidebar-menu-wrapper-visible):not(.vrtx-editor-frontpage) {
  .vrtx-frontpage-box.museum-sub-header {
    margin-top: -140px;
  }
}

.vrtx-frontpage-box.museum-sub-header {
  margin-left: 300px;
  max-width: 560px;

  .vrtx-box-content {
    p {
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }
}
#main .vrtx-frontpage-box.museum-sub-header {
  .vrtx-box-content {
    h2 {
      font-size: 5.6rem;
      line-height: 6.4rem;
      letter-spacing: 0.2px;
      margin-bottom: 40px;
    }
  }
}

.sidebar-menu-wrapper {
  .sidebar-search.search-form {
    input[type=text] {
      .placeholderColor (#000, #000);
    }
  }
}

// Media wide (calibrate how far dragging media page-top up, according to header height)
body:not(.sidebar-menu-wrapper-visible) {
  .grid-container.page-top-white,
  .grid-container.page-top {
    margin-top: -200px !important;
  }
  &.the-frontpage {
    .grid-container.page-top-white,
    .grid-container.page-top {
      margin-top: -304px !important;
    }
  }
  @media @desktopSmallBreakpoint {
    .grid-container.page-top-white,
    .grid-container.page-top {
      margin-top: -200px !important;
    }
    &.the-frontpage {
      .grid-container.page-top-white,
      .grid-container.page-top {
        margin-top: -200px !important;
      }
    }
  }
  @media @responsiveBreakpoint {
    .grid-container.page-top-white,
    .grid-container.page-top {
      margin-top: -215px !important;
    }
    &.the-frontpage {
      .grid-container.page-top-white,
      .grid-container.page-top {
        margin-top: -215px !important;
      }
    }
  }
  @media only screen and (max-width: 360px) {
    &.the-frontpage:not(.sidebar-menu-wrapper-visible):not(.vrtx-editor-frontpage) {
      .grid-container.page-top-white,
      .grid-container.page-top {
        margin-top: -185px !important;
      }
    }
  }  
}

body.has-page-top-row-white,
body.has-page-top-row {
  #logo {
    opacity: 90%;
  }
}

body.has-page-top-row-white {
  #logo {
    background-image: url("../images/logo-white.svg");
  }
  &.en {
    #logo {
      background-image: url("../images/logo-en-white.svg");
    }
  }
  &.the-frontpage:not(.sidebar-menu-wrapper-visible):not(.vrtx-editor-frontpage) #logo {
    background-image: url("../images/logo-big-white.svg");
  }
  &.en.the-frontpage:not(.sidebar-menu-wrapper-visible):not(.vrtx-editor-frontpage) #logo {
    background-image: url("../images/logo-big-white.svg");
  }
}

// Global sidebar menu
/* Comment out/in this if more contrast is needed 
.sidebar-menu-wrapper {
  .sidebar-search.search-form {
    input[type=text] {
      border-color: #fff;
      color: #fff;

      .placeholderColor (#fff, #fff);
    }
    button[type=submit] {
      background-image: url(/vrtx/dist/resources/uio2/css/images/sidebar/magnifying-glass-white.svg);
    }
  }
}
.sidebar-menu-wrapper-visible {
  #back-to-uio-small-logo {
    background-image: url("/vrtx/dist/resources/uio2/css/images/footer/uio-logo-no.svg");
  }
  #head-wrapper {
    a.button-header-smaller:not(.feedback-yes):not(.feedback-no):before {
      background-image: url("/vrtx/dist/resources/uio2/css/images/arrows/arrow-big-white.svg");
    }
    #logo {
      background-image: url("../images/logo-white.svg")
    }
  }
  .sidebar-services-language-menu {
    border-color: #fff;
  }
}

.sidebar-menu-wrapper-visible.en {
  #back-to-uio-small-logo {
    background-image: url("/vrtx/dist/resources/uio2/css/images/footer/uio-logo-en.svg");
  }
  #head-wrapper {
    #logo {
      background-image: url("../images/logo-en-white.svg")
    }
  }
}

body.sidebar-menu-wrapper-visible .sidebar-menu-toggle:before {
  background-image: url(/vrtx/dist/resources/uio2/css/images/sidebar/sidebar-close-white.svg);
}
*/

.sidebar-menu-wrapper-visible #head-wrapper,
.sidebar-menu{
  background: @globalMenuBg;

  .button, .ui-dialog.ui-widget button, .vrtx-button:not(form), button, input[type=button], input[type=reset], input[type=submit] {
    border-color: @globalMenuColor;
  }

  &,
  a {
    color: @globalMenuColor;
  }
}

// Row color changes

.sidebar-menu-wrapper-visible .grid-container-top {
  display: none;
}

// When img-special-left, img-special-right follow eachother in a colored row,
// you may want some space between images and a header
#main .row-all-colored {
  .vrtx-frontpage-box {
    &.img-special-left,
    &.img-special-right {
      &.img-special-multiple-colored-rows {
        .vrtx-box-content,
        .vrtx-frontpage-box-picture {
          margin-top: -20px;
        }
      }
      &.img-special-multiple-colored-rows-first {
        .vrtx-box-content,
        .vrtx-frontpage-box-picture {
          margin-top: -60px;
        }
      }
      &.img-special-multiple-colored-rows-last {
        .vrtx-box-content,
        .vrtx-frontpage-box-picture {
          margin-bottom: 0px;
        }
      }
    }
  }
}
#main #vrtx-main-content .row-all-colored { // Needed higher specificity
  &.grid-container.row-first-colored.row-last-colored .vrtx-frontpage-box.img-special-color-header {
    > h2 {
      margin-bottom: -80px;
    }
  }
}

#vrtx-frontpage #vrtx-main-content .row-all-colored:not(.row-all-hidden-boxes):last-child {
  margin-bottom: 55px;

  &.grid-color-grey,
  &.grid-color-green,
  &.grid-color-black {
    margin-bottom: 25px;
  }
}



#main .row-all-colored.grid-color-green {
  background: @boxRowGreenBg;

  .vrtx-frontpage-box.green-box {
    background: @boxRowGreenBg;
  }
}
#main .row-thirds-double:not(.row-one-colored):not(.row-thirds-double-no-vertical-line).row-all-colored,
#main .row-all-colored.grid-color-grey {
  background: @boxRowCustomGreyBg;
}
#main .row-all-colored.grid-color-black {
  background: @boxRowBlackBg;
}

/* Green row / box */

#vrtx-structured-article #main,
#vrtx-frontpage #main {
  .vrtx-frontpage-box.green-box {
    /* Comment in/out this if more contrast is needed
    .read-more, .read-more-small, .vrtx-more a {
      //box-shadow: 0 0.03em 0 0 #fff;

      &:before {
        background-image: url("/vrtx/dist/resources/uio2/css/images/arrows/arrow-big-white.svg");
      }
    }

    ul.only-links li a:not(.button):not(.button-large):before {
      background-image: url("/vrtx/dist/resources/uio2/css/images/arrows/arrow-big-white.svg");
    }
    */

    .vrtx-event-component .vrtx-event-component-item .vrtx-event-component-misc,
    table th,
    &,
    a {
      color: @exhibitionInfoColor;
    }
  }
}

#vrtx-structured-article #main,
#vrtx-frontpage #main {
  .grid-container:not(.grid-color-green) .vrtx-frontpage-box.green-box {
    background: @exhibitionInfoBg;

    &:first-child {
      padding-left: 40px;
    }
    &:last-child {
      padding-right: 40px;
    }

    > *:first-child { padding-top: 40px;    }
    > *:last-child  { padding-bottom: 40px; }
  }
}

// Eventslisting
#vrtx-event-listing #main #vrtx-events-nav {
  display: none;
}

/* Exhibition */

#main .exhibition-info {
  padding: 40px 40px 50px 40px;
  margin: 40px 0 80px 0;
  background: @exhibitionInfoBg;
  color: @exhibitionInfoColor;
  max-width: 1000px;

  >*:first-child {
    font-size: 2.8rem;
    line-height: 3.8rem;
    margin-bottom: 10px;
  }
  >*:last-child {
    margin-bottom: 0;
  }
  p {
    font-size: 2.3rem;
    line-height: 3.2rem;
  }
  a {
    color: @exhibitionInfoColor;
  }
}

body.exhibition {
  .vrtx-date-info,
  .vrtx-social-components {
    display: none;
  }
  // Fix when floating elements at bottom of article
  #vrtx-main-content,
  #vrtx-main-user {
    &:after {
      content:"";
      display:block;
      clear:both;
    }
  }
}

// Exhibition tags

.exhibition .vrtx-tags {
  display: none;
}

main .categories {
  margin-top: 10px;
  dd {
    // use !important here as an exception (in small controlled enviroment,
    //                                      categories will only be used for this on historisk museum)
    padding: 2px 10px 2px 10px !important;
    margin: 0 10px 12px 0 !important;
    width: auto !important;
    float: left !important;
    clear: both !important;

    display: block;
    background: @exhibitionCategoryBg;
    color:  @exhibitionCategoryColor;
    letter-spacing: 0.2px;
    &:after {
      content: "";
      width: 100%;
      display: block;
    }
  }
}

// Visit

.exhibition-visit .vrtx-frontpage-box.vrtx-frontpage-box-picture {
  .vrtx-box-content>h2:first-child {
    margin-bottom: 25px;
  }
}

// Special

html.special-page-config body:not(.ritmo) #footer-wrapper:not(.faculty-institute-footer) #footers,
html.special-page-config-header-minimal body:not(.ritmo) #footer-wrapper:not(.faculty-institute-footer) #footers {
  background: none;
}

html.special-page-config-header-minimal.special-page-config-header-minimal #head-wrapper,
html.special-page-config.special-page-config-header-minimal #head-wrapper {
  min-height: 180px;
}

// Fixes for when is underlay in frontpage editor

.vrtx-editor-frontpage #logo {
  height: 100px;
  width: 80px;
}

@media @desktopSmallBreakpoint {
  .the-frontpage:not(.vrtx-editor-frontpage) #logo {
    height: 100px;
    width: 80px;
    position: static;
  }
  .the-frontpage:not(.vrtx-editor-frontpage) #global-menu {
    margin-top: 0px;
  }
  .the-frontpage:not(.vrtx-editor-frontpage) #vrtx-main-content {
    margin-top: 0px;
  }
  .the-frontpage:not(.sidebar-menu-wrapper-visible):not(.vrtx-editor-frontpage) #logo {
    background-image: url("../images/logo-big.svg");
    height: 260px;
    width: 280px;
    z-index: 0;
    position: absolute;
  }
  .the-frontpage:not(.sidebar-menu-wrapper-visible):not(.vrtx-editor-frontpage) .vrtx-frontpage-box.museum-sub-header {
    margin-top: 0px;
  }
  body.en.the-frontpage:not(.sidebar-menu-wrapper-visible):not(.vrtx-editor-frontpage) #logo {
    background-image: url("../images/logo-big.svg");
  }
  .vrtx-frontpage-box.museum-sub-header {
    margin-left: 360px;
    max-width: 500px;
  }
}

@media only screen and (max-width: 360px) {
  .the-frontpage:not(.sidebar-menu-wrapper-visible):not(.vrtx-editor-frontpage) #head-wrapper {
    min-height: 170px;
  }
}

@media @responsiveBreakpoint {
  .the-frontpage:not(.sidebar-menu-wrapper-visible):not(.vrtx-editor-frontpage) #logo {
    background-size: 100% auto;
  }

  .vrtx-frontpage-box.museum-sub-header {
    margin-left: 0px;
    max-width: 100%;
    margin-top: 40px;
  }
  .the-frontpage:not(.sidebar-menu-wrapper-visible):not(.vrtx-editor-frontpage) #logo {
    height: 121px;
    width: 130px;
  }
  .the-frontpage:not(.sidebar-menu-wrapper-visible):not(.vrtx-editor-frontpage) .vrtx-frontpage-box.museum-sub-header {
    margin-top: 40px;
  }
  #main .vrtx-frontpage-box.museum-sub-header .vrtx-box-content h2 {
    font-size: 4rem;
    line-height: 5rem;
  }

  .exhibition-price-opening-hours .vrtx-frontpage-box + .vrtx-frontpage-box {
    margin-top: 40px;
  }

  .exhibition #main .exhibition-tickets {
    margin-bottom: 0;
  }
  #main #right-main,
  #main #total-main {
    .row-all-colored:not(.grid-color-grey):not(.grid-color-green):not(.grid-color-black) {
      .vrtx-frontpage-box.vrtx-frontpage-box-picture.img-special-left .vrtx-frontpage-box-picture,
      .vrtx-frontpage-box.vrtx-frontpage-box-picture.img-special-right .vrtx-frontpage-box-picture,
      .vrtx-frontpage-box.vrtx-frontpage-box-picture.img-special-left .vrtx-frontpage-box-picture,
      .vrtx-frontpage-box.vrtx-frontpage-box-picture.img-special-right .vrtx-frontpage-box-picture {
        margin-top: 0;
      }
    }
  }

  #vrtx-frontpage #main .grid-container.row-last-colored:not(.row-all-no-margin-bottom):not(.row-all-hidden-boxes):not(.row-all-hidden-boxes-responsive) + .row-first-colored {
    margin-top: 0;
  }

  #vrtx-frontpage #main .row-all-colored:not(.row-all-no-margin-bottom):not(.row-all-hidden-boxes).grid-color-green + .row-all-colored:not(.row-all-hidden-boxes).grid-color-green,
  #vrtx-frontpage #main .row-all-colored:not(.row-all-no-margin-bottom):not(.row-all-hidden-boxes).grid-color-black + .row-all-colored:not(.row-all-hidden-boxes).grid-color-green,
  #vrtx-frontpage #main .row-all-colored:not(.row-all-no-margin-bottom):not(.row-all-hidden-boxes).grid-color-green + .row-all-colored:not(.row-all-hidden-boxes).grid-color-black,
  #vrtx-frontpage #main .row-all-colored:not(.row-all-no-margin-bottom):not(.row-all-hidden-boxes).grid-color-black + .row-all-colored:not(.row-all-hidden-boxes).grid-color-black {
    margin-top: -40px;
  }

  #main #vrtx-main-content .row-all-colored {
    &.grid-container.row-first-colored.row-last-colored .vrtx-frontpage-box.img-special-color-header {
      > h2 {
        margin-bottom: -20px;
      }
    }
  }

  #vrtx-frontpage #vrtx-main-content .row-all-colored:not(.row-all-hidden-boxes):last-child {
    margin-bottom: 15px;

    &.grid-color-grey,
    &.grid-color-green,
    &.grid-color-black {
      margin-bottom: 0;
    }
  }

  #vrtx-structured-article #main #vrtx-main-content,
  #vrtx-frontpage #main #vrtx-main-content {
    .grid-container:not(.grid-color-green) .vrtx-frontpage-box.green-box {
      background: @exhibitionInfoBg;

      + .vrtx-frontpage-box.green-box {
        margin-top: -80px;
      }

      &:first-child {
        padding-left: 15px;
      }
      &:last-child {
        padding-right: 15px;
      }

      > *:first-child { padding-top: 20px;    }
      > *:last-child  { padding-bottom: 20px; }
    }
  }

  // TMP
  #vrtx-frontpage.total-main #main #vrtx-frontpage-introduction {
    margin-left: 0;
    margin-right: 0;
    padding-left: 15px;
    padding-right: 15px;
  }
}
