@footerBg: #202020;
@headerLangTooltipBg: #312D28;

/* The usual/automatic grey row bg is disabled with css on historiskmuseum and NHM
 *
 * Own decorating step for exhibition articles:
 * /vrtx/decorating/templates/exhibition.tmp (see also config.txt)
 * Own decorating step with social media row for whole site:
 * /vrtx/decorating/templates/social-media.tmp (see also config.txt)
 */
 
@import '../../../../all/src/resources/css/head-language-switch.less';

#header {
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: start;
}

#header-items {
  display: flex;
  flex-direction: row;
  align-items: start;

  >* {
    flex: 1;
    margin: 0 20px;

    &:first-child {
      margin-left: 0;
    }
  }
  #back-to-uio-small-logo {
    flex: 8;
  }
}

#head-wrapper #head #header-language {
  align-self: start;
}

#header-language {
  padding: 8px 0px 7px 0;
  margin-right: 20px;
}

#header-language > span,
#header-language > a {
  text-transform: initial;
  padding: 1px 8px;
}
#header-language > a {
  padding-right: 2px;
}

#back-to-uio-small-logo {
  background: url("/vrtx/dist/resources/uio2/css/images/footer/uio-logo-no-black.svg") no-repeat 0 0;
  background-size: auto 100%;
  height: 56px;
  width: 222px;
  display: block;
}

body.en #back-to-uio-small-logo {
  background-image: url("/vrtx/dist/resources/uio2/css/images/footer/uio-logo-en-black.svg");
  width: 184px;
}

.sidebar-menu-toggle,
#head-wrapper #head #header-language {
  padding-left: 10px;
  padding-right: 10px;
}
.sidebar-menu-toggle {
  padding-top: 0px;
  padding-bottom: 0px;
}


// Change occurance of menu icon (before instead of after, like on UiO)
body.sidebar-menu-wrapper-visible .sidebar-menu-toggle span,
.sidebar-menu-toggle span {
  top: -5px;
}

.sidebar-menu-toggle:after {
  display: none;
}

.sidebar-menu-toggle:before {
  content: "";
  background: url("/vrtx/dist/resources/images/menu-open.svg") no-repeat center left;
  background-size: auto 100%;
  height: 32px;
  width: 32px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 5px;
}

body.sidebar-menu-wrapper-visible .sidebar-menu-toggle:before {
  background-image: url("/vrtx/dist/resources/images/menu-close.svg");
  height: 32px;
  width: 32px;
  top: 5px;
}

@media @responsiveBreakpoint {
  #header-language {
    margin-right: 10px;
  }
  #header {
    display: block;
  }
  .sidebar-menu-toggle:before {
    margin-right: 0;
  }
  #header #logo {
    max-width: 40vw;
  }
}

// Media wide top

body.has-page-top-row-white {
  &:not(.sidebar-menu-wrapper-visible) { // Not visible menu - white logo
    #back-to-uio-small-logo,
    #logo {
      filter: invert(0);
    }
  }
  &.sidebar-menu-wrapper-visible { // Visible menu - black logo (inverted animated via filter to white)
    #back-to-uio-small-logo,
    #logo {
      filter: invert(100%);
    }
  }
  #back-to-uio-small-logo {
    background-image: url(/vrtx/dist/resources/uio2/css/images/footer/uio-logo-no.svg);
  }
  &.en {
    #back-to-uio-small-logo {
      background-image: url(/vrtx/dist/resources/uio2/css/images/footer/uio-logo-en.svg);
    }
  }
}

html.doc-ready {
  body.has-page-top-row-white {
    &:not(.sidebar-menu-wrapper-visible) { // Not visible menu - white logo
      #back-to-uio-small-logo,
      #logo {
        transition: filter linear 0.4s;
      }
    }
  }
  body.has-page-top-row-white,
  body.has-page-top-row {
    &:not(.sidebar-menu-wrapper-visible) {
      .sidebar-menu-toggle,
      #head-wrapper #head #header-language {
        transition: background linear 0.5s;
      }
    }
  }
}

body.has-page-top-row-white,
body.has-page-top-row {
  &:not(.sidebar-menu-wrapper-visible) {
    .sidebar-menu-toggle,
    #head-wrapper #head #header-language {
      background-color: rgba(255,255,255,0.9); // #ecfdc0
    }
    #header-items {
      .button {
        background-color: rgba(255,255,255,0.9);
        padding-top: 10px;
        padding-bottom: 9px;
        border: none;

        &:hover,
        &:focus {
          background-color: rgba(255,255,255,0.9);
        }
      }
    }
  }
  &.sidebar-menu-wrapper-visible {
    .sidebar-menu-toggle,
    #head-wrapper #head #header-language {
      background-color:rgba(255, 255, 255, 0);
    }
    #header-items {
      .button {
        background-color:rgba(255, 255, 255, 0);
      }
    }
  }
}

// Header smaller button

a.button-header-smaller:not(.feedback-yes):not(.feedback-no) {
  padding: 10px 14px 9px 40px;
  width: auto;
  white-space: nowrap;
  font-size: 1.6rem;
  line-height: 2.4rem;
  border: none;

  &:hover,
  &:focus {
    padding-left: 40px;
  }

  &:before {
    content: "";
    background: url(/vrtx/dist/resources/uio2/css/images/arrows/arrow-big.svg) no-repeat;
    background-size: 100% auto;
    position: absolute;
    top: 16px;
    left: 14px;
    height: 0.9em;
    width: 0.9em;
  }

  &:after {
    display: none;
  }
}

#left-menu-same-level-folders .vrtx-breadcrumb-menu {
  background: transparent;  
}
.vrtx-introduction-image {
  max-width: min(1200px, 100%);
}

/* Footer */

#footer-wrapper {
  background: @footerBg;

  #footers {
    padding-top: 50px;
    background-image: none;

    .col-2-5 {
      margin-top: 92px;
    }
  }

  #footer-logo {
    font-size: 3.3rem;
    line-height: 4rem;
    margin-top: 0;
    margin-bottom: 60px;
  }
}
body.en #footer-wrapper #footers {
  background-image: none;
}

// Image is always at top
#vrtx-structured-article .vrtx-introduction-image.small-intro-image {
  margin: 0 0 75px 0;
  float: none;
}

#vrtx-frontpage #vrtx-frontpage-introduction, .display-as-introduction p,
#vrtx-frontpage .vrtx-introduction, .vrtx-introduction, .vrtx-organizational-unit-introduction {
  font-family: inherit;

  .navigation-links {
    ul:last-child {
      margin-bottom: 0;
    }
  }
}

// Visiting - price and opening hours tables

table.visit-table {
  /*max-width: 400px;*/
  font-size: 2.3rem;

  &.vertical.minimal {
    tbody th[scope=row],
    tbody td {
      border-width: 0;
      border-color: #808080;
      padding: 0 0 10px 0;
    }
    th {
      font-weight: normal;
    }
    tbody th[scope=row]+td {
      padding-left: 50px;
    }
  }

  + .only-links {
    margin-top: -10px;
  }
}

// Opening hours on all openings hours page

.opening-hours-include .vrtx-shared-text-description>*:not(table):not(.table-wrapper-responsive) {
  display: none;
}

body:not(.en) .opening-hours-include .vrtx-shared-text-description {
  &:last-child {
    display: none;
  }
}
body.en .opening-hours-include .vrtx-shared-text-description {
  &:first-child {
    display: none;
  }
}

#main .opening-hours,
#main .opening-hours-botanic,
#main .opening-hours-exhibitions,
#main .prices {
  h2 {
    margin-bottom: 40px;

    + h3 {
      margin-top: 0;
    }
  }
}

body:not(.vrtx-mode-edit) {
  #main .opening-hours-botanic,
  #main .opening-hours-exhibitions,
  #main .opening-hours,
  #main .prices {
    &:not(.left):not(.right) {
      > h2 {
        display: none;
      }
    }
  }
}

// More exhibitions, events

#main .feed-random-more {
  margin-top: 100px;

  .with-images ul li {
    margin-bottom: 0;
  }

  .vrtx-more {
    margin-top: 30px;
  }
}

// Regular grey/alternate row color set to transparent
#main .row-all-colored:not(.grid-color-yellow):not(.grid-color-green):not(.grid-color-black):not(.grid-color-grey) {
  background: #fff;
  padding-top: 0;
  padding-bottom: 0;
}
#vrtx-frontpage:not(.total-main) .row-all-colored:not(.grid-color-yellow):not(.grid-color-green):not(.grid-color-black):not(.grid-color-grey) {
  padding-left: 0;
  padding-right: 0;
}

#main .row-all-colored.grid-color-black {
  &,
  a,
  table.visit-table.vertical.minimal tbody th[scope=row],
  table.visit-table.vertical.minimal tbody td {
    color: #fff;
  }
  a {
    text-decoration-color: #fff;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .button, .ui-dialog.ui-widget button, .vrtx-button:not(form), button, input[type=button], input[type=reset], input[type=submit] {
    border: 1px solid #fff;
    text-decoration: none;

    &:after {
      background-image: url("/vrtx/dist/resources/uio2/css/images/arrows/arrow-big-white.svg");
    }

    &:hover,
    &:focus {
      background: rgba(80, 85, 88, 1);
    }
  }
}

#vrtx-frontpage #main .row-all-colored:not(.row-all-no-margin-bottom):not(.row-all-hidden-boxes) + .row-all-colored:not(.row-all-hidden-boxes) {
  margin-top: 0;
}

#vrtx-frontpage #main .row-all-colored:not(.row-all-no-margin-bottom):not(.row-all-hidden-boxes) {
  &.grid-color-grey,
  &.grid-color-yellow,
  &.grid-color-green,
  &.grid-color-black {
    + .row-all-colored:not(.row-all-hidden-boxes) {
      &.grid-color-grey,
      &.grid-color-yellow,
      &.grid-color-green,
      &.grid-color-black {
        margin-top: -80px;
      }
    }
  }
}

#main .row-all-colored .vrtx-frontpage-box.img-special-left,
#main .row-all-colored .vrtx-frontpage-box.img-special-right {
  &:not(.row-color-yellow):not(.row-color-black) {
    .vrtx-box-content,
    .vrtx-frontpage-box-picture {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

#vrtx-frontpage #main .grid-container .vrtx-frontpage-box {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

// Fix issue with to aggressive breaking of hyphens:
// https://rt.uio.no/Ticket/Display.html?id=6460583
// (until https://developer.mozilla.org/en-US/docs/Web/CSS/hyphenate-limit-chars are supported)
.grid-container.fullwidth-video .text-box {
  text-wrap: balance;
}


// New sub header box type (a variant of img-special-*)

.vrtx-frontpage-box.sub-header-box-img-left,
.vrtx-frontpage-box.sub-header-box-img-right {
  display: grid;
  gap: 0 60px;
  grid-template-columns: minmax(340px, 420px) minmax(280px, 720px);
  align-items: center;

  .vrtx-frontpage-box-picture {
    order: 2;
    margin: 0;
  }
  .vrtx-box-content {
    order: 1;
  }

  h2 {
    font-size: 5rem;
    line-height: 6rem;
    text-decoration: none;
  }
}

#main {
  .vrtx-frontpage-box.sub-header-box-img-left,
  .vrtx-frontpage-box.sub-header-box-img-right {
    h2 {
      font-size: 5rem;
      line-height: 6rem;
    }
  }
}

#vrtx-frontpage #main {
  .vrtx-frontpage-box.sub-header-box-img-left,
  .vrtx-frontpage-box.sub-header-box-img-right {
    &:after {
      display: none;
    }
  }
}

.vrtx-frontpage-box.sub-header-box-img-left {
  grid-template-columns: minmax(280px, 720px) minmax(340px, 420px);

  .vrtx-frontpage-box-picture {
    order: 1;
  }
  .vrtx-box-content {
    order: 2;
  }
}

// Social media container on all pages (see decoration step, /vrtx/decorating/templates/social-media.tmp)

#main {
  margin-bottom: 80px;

  .social-media-container {
    margin-top: 25px;
    clear: both;
    width: ~"var(--width)";
    margin-left: ~"calc(calc(-1 * calc(calc(var(--width) - 100%) / 2)) - 100px)";

    .row {
      max-width: 1440px;
      min-width: 1050px;
      padding-right: 20px;
      padding-left: 220px;
      margin: 0 auto;
    }

    h2 {
      font-size: 3.4rem;
      line-height: 4.1rem;
      margin-bottom: 40px;
    }
  }
}

body:not(#vrtx-frontpage) .social-media-container {
  margin-top: 105px;
  margin-left: ~"min(-220px, calc(calc(-1 * calc(calc(var(--width) - 100%) / 2)) - 100px))";
  &:last-child {
    margin-bottom: -80px;
  }
}

#vrtx-frontpage #vrtx-main-content .row-all-colored:not(.row-all-hidden-boxes):last-child {
  margin-bottom: -105px;
}

#footer-wrapper .footer-links {
  margin-top: -5px;
  p {
    margin-top: 0;
  }
}

/* Clearfixes with social media container */

#vrtx-content,
#vrtx-calendar-listing {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

#head-wrapper .sidebar-menu-toggle {
  width: 103px;
}

@media @desktopSmallBreakpoint {
  #header-items {
    display: block;

    .button-header-smaller,
    #back-to-uio-small-logo {
      display: none;
    }
    #header-lang {
      margin-right: 130px;
      margin-left: 0;

      #header-lang-tooltip {
        right: 0;
      }
    }
  }

  #main #left-main {
    padding-top: 13px;
  }

  .sidebar-menu-inner-wrapper {
    overflow: hidden;
  }

  .sidebar-menu-inner-wrapper #back-to-uio-small-logo {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  #main .social-media-container {
    margin-left: -25px;
  }

  #main .social-media-container .row {
    width: auto;
  }
  body#vrtx-frontpage .social-media-container .row {
    min-width: 0;
    padding-left: 25px;
  }
  body:not(#vrtx-frontpage) #main #total-main .social-media-container,
  body:not(#vrtx-frontpage) #main #right-main .social-media-container {
    margin-left: -25px;
    padding-left: 25px;
    padding-right: 20px;
  }
  body:not(#vrtx-frontpage) .social-media-container .row {
    max-width: 1440px;
    min-width: 0;
    padding-right: 20px;
    padding-left: 0px;
  }

  table.visit-table {
    font-size: 2rem;
  }

  table.visit-table.vertical.minimal tbody th[scope=row] + td {
    padding-left: 25px;
  }
}

@media @responsiveBreakpoint {
  // Museums want to display language switch on mobile also (although it's also inside menu)
  #header-language {
    display: grid;
  }

  #head-wrapper .sidebar-menu-toggle {
    width: auto;
  }

  table.visit-table {
    font-size: 1.8rem;
  }

  #vrtx-structured-article .vrtx-introduction-image.small-intro-image {
    margin-bottom: 40px;
  }

  #footer-wrapper-back-to-uio {
    padding-top: 20px;
  }

  .vrtx-frontpage-box.sub-header-box-img-left,
  .vrtx-frontpage-box.sub-header-box-img-right {
    &.vrtx-frontpage-box-picture {
      display: block;
      align-items: initial;
      grid-template-columns: none;
      gap: initial;

      .vrtx-frontpage-box-picture {
        margin-bottom: 40px;
      }
    }
  }

  body:not(#vrtx-frontpage) #main #total-main .social-media-container,
  body:not(#vrtx-frontpage) #main #right-main .social-media-container {
    margin-left: -15px;
    margin-top: 60px;
    padding-left: 15px;
    padding-right: 15px;

    &:last-child {
      margin-bottom: -120px;
    }
  }

  #vrtx-frontpage #main #total-main .social-media-container .row,
  #vrtx-frontpage #main #right-main .social-media-container .row {
    padding-left: 0;
    padding-right: 0;

    .row {
      padding-left: 0;
      padding-right: 0;
    }

    div.left {
      margin-bottom: 30px;
    }
  }

  #vrtx-frontpage #main .grid-container.row-all-colored:not(.grid-color-yellow):not(.grid-color-green):not(.grid-color-black):not(.grid-color-grey) {
    padding-top: 0;
    padding-bottom: 0;
  }

  #vrtx-frontpage #vrtx-main-content .row-all-colored:not(.row-all-hidden-boxes):last-child {
    margin-bottom: 0;
  }
}


@media print {
  body { // Extra specificity because imported
    #header-items,
    .social-media-container {
      display: none;
    }

    #footer-wrapper {
      background: transparent;
    }
  }
}
